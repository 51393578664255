
import Vue from 'vue';
import utils from '../../util';
import { User } from '../../types/users';
import { Contacts, AdvertiserInfo } from '@point/utility-classes';
import analytics from '../../mixins/analytics';

export default Vue.extend({
  name: 'linearAdminDetails',
  props: ['ppid', 'action'],
  mixins: [analytics],
  data: (): {
    hasBasicEdit: boolean;
    hasFullEdit: boolean;
    hasReportAdvertiserEdit: boolean;
    hasReportAgencyEdit: boolean;
    viewAllStations: boolean;
    users: User[] | null;
    checkingGA: boolean;
    activeTab: string;
    isShowArchive: boolean;
    isArchived: boolean;
  } => ({
    hasBasicEdit: false,
    hasFullEdit: false,
    hasReportAdvertiserEdit: false,
    hasReportAgencyEdit: false,
    viewAllStations: false,
    checkingGA: false,
    users: null,
    activeTab: 'accounts',
    isShowArchive: false,
    isArchived: false,
  }),
  async created(): Promise<void> {
    await Promise.all([this.loadAdvertiserInfo(), this.loadAdvertiserContacts()]);
    const timer = setInterval(() => {
      // wait until advertiser has loaded
      if (this.advertiserInfo) {
        clearInterval(timer);
        this.checkEditRights();
      }
    }, 250);
  },
  mounted() {
    if (this.action && this.action === 'website') {
      setTimeout(() => {
        this.editAdvertiser('website');
      }, 500);
    }
  },
  components: {
    AdvertiserInfo: () => import('./components/advertiserInfo.vue'),
    AccountsTabNew: () => import('./components/accountsTabNew.vue'),
    Contacts: () => import('./components/contacts.vue'),
    ArchivedCampaigns: () => import('./components/archivedCampaigns.vue'),
  },
  methods: {
    async updateAdvertiserContacts(contacts: Contacts): Promise<void> {
      try {
        await this.$store.dispatch('advertiser/updateAdvertiserContacts', {
          contacts: contacts,
          userId: this.$store.state.customer.user.email,
          propertyId: this.ppid,
        });
        await this.loadAdvertiserContacts();
      } catch (error) {
        console.error('Error updating advertiser contacts', error);
      }
    },
    async updateAdvertiserInfo(advertiserInfo: AdvertiserInfo): Promise<void> {
      const oldLogo = this.advertiserInfo?.oldLogo;
      try {
        this.$store
          .dispatch('advertiser/updateAdvertiserInfo', {
            advertiserLogo: advertiserInfo?.advertiserLogo,
            WURL: advertiserInfo?.WURL,
            phone: advertiserInfo?.phone,
            isShowAllTactics: advertiserInfo?.isShowAllTactics,
            gamAutoOnboarding: advertiserInfo?.gamAutoOnboarding,
            propertyId: this.ppid,
          })
          .then(() => {
            // only track logo changes if something actually changed
            if (oldLogo !== advertiserInfo?.advertiserLogo) {
              this.analyticsLogoChangeTrack(
                this.trackValue.EVENT_EDIT_ADVERTISER_INFO,
                this.trackValue.TRACK_EDIT_ADVERTISER_LOGO,
                advertiserInfo?.advertiserLogo, // new logo
                oldLogo, // old logo, if available
              );
            }
            this.loadAdvertiserInfo();
          });
      } catch (error) {
        console.error('Error updating advertiser info', error);
      }
    },
    async checkEditRights(): Promise<void> {
      this.hasBasicEdit = utils.hasRight(this.$store.state.customer.user, ['ADVERTISER_EDIT_BASIC']);
      this.hasFullEdit = utils.hasRight(this.$store.state.customer.user, ['ADVERTISER_EDIT_ALL']);
      this.hasReportAgencyEdit = utils.hasRight(this.$store.state.customer.user, ['AGENCY_EDIT_SCHEDULED_REPORT']);
      this.hasReportAdvertiserEdit = utils.hasRight(this.$store.state.customer.user, ['EDIT_SCHEDULED_REPORT']);
    },
    formattedAccount(account: string): string | null {
      return utils.googleSEMFormattedCustomerId(account);
    },
    async loadAdvertiserInfo(): Promise<void> {
      try {
        await this.$store.dispatch('advertiser/getAdvertiserInfo', { id: this.ppid });
      } catch (error) {
        console.error('Error loading advertiser info', error);
      }
    },
    async loadAdvertiserContacts(): Promise<void> {
      try {
        await this.$store.dispatch('advertiser/getContacts', this.ppid);
      } catch (error) {
        console.error('Error loading advertiser contacts', error);
      }
    },
    openDashboard(): void {
      this.$router.push({
        name: 'home',
        query: { id: this.advertiserInfo.id },
      });
    },
    openScheduledReports(): void {
      this.$router.push({ name: 'scheduledreports', query: { advertiser: this.advertiserInfo.id } });
    },
    editAdvertiser(input: string): void {
      if (input === 'website') {
        this.$emit('edit-business-details', input);
      } else if (input === 'contact') {
        this.activeTab = 'contacts';
      }
    },
    openArchive(): void {
      this.isShowArchive = true;
    },
  },
  computed: {
    loading(): boolean {
      return (
        this.$store.state.advertiser?.isAdvertiserInfoLoading ||
        this.$store.state.advertiser?.isContactsLoading ||
        this.$store.state.advertiser?.isUpdateAdvertiserLoading ||
        this.$store.state.advertiser?.isUpdateContactsLoading
      );
    },
    error(): string {
      return (
        this.$store.state.advertiser?.advertiserInfoError ||
        this.$store.state.advertiser?.contactsError ||
        this.$store.state.advertiser?.updateAdvertiserError ||
        this.$store.state.advertiser?.updateContactsError
      );
    },
    editRights(): object {
      return {
        hasBasicEdit: this.hasBasicEdit,
        hasFullEdit: this.hasFullEdit,
        ga4Connector: utils.hasRight(this.$store.state.customer.user, ['SCREEN_ADVERTISER_MGMT']),
        allConnectors: utils.hasRight(this.$store.state.customer.user, ['SCREEN_ADVERTISER_MGMT_ENABLE_CONNECTORS']),
      };
    },
    advertiserInfo(): AdvertiserInfo | null {
      return this.$store.state?.advertiser?.advertiserInfo?.data || null;
    },
    usersForThisAccount(): Array<User> | null {
      if (!this.users) return null;
      return this.users.reduce((all: Array<User>, u: User) => {
        if (u.accounts) {
          if (u.accounts.find(a => a === this.$route.query.ppid)) all.push(u);
          return all;
        }
        return all;
      }, []);
    },
    isAdmin() {
      return (
        utils.hasRight(this.$store.state?.customer?.user, ['AGENCY_ADMIN']) ||
        this.$store.state.customer.user.is_superuser
      );
    },
  },
});
